
    import Vue from 'vue';
    import Component from "vue-class-component";
    import ApiClient from "@/utilities/ApiClient";
    import Utils from "@/utilities/Utils";
    import { ActiveElement, Chart, ChartEvent, LegendItem, registerables } from "chart.js";
    import { ChartRequest } from "@/model/api/ChartRequest";
    import { AdviceReportRequest } from "@/model/api/AdviceReportRequest";
    import { ChartData } from "@/model/ChartData";
    import { UserAgreementCustomer } from "@/model/User";
    import { KeyMetrics, IKeyMetrics } from "@/model/KeyMetrics";
    import { Watch } from "vue-property-decorator";
    import ChartFilters from "@/components/ChartFilters.vue";
    import ApiButton from "@/components/ApiButton.vue";
    import PurchasesDialogue from '@/components/PurchasesDialogue.vue';
    import store from "@/store/store";
    import * as toastr from "toastr";
import { ChartFilterOptions } from '@/model/Enums';

    @Component({ components: { ChartFilters, ApiButton, PurchasesDialogue } })
    export default class Dashboard extends Vue {

        // 
        // -- lifecycle hooks
        //

        async mounted() {
            Chart.register(...registerables);
        }

        //
        // -- properties
        //

        private initDone = false;

        customers: Array<UserAgreementCustomer> = [];
        hiddenDropAccounts: Array<string> = [];

        cprpdRequest: ChartRequest = new ChartRequest();
        breakDownRequest: ChartRequest = new ChartRequest();

        cprpdData: ChartData<Date> = new ChartData<Date>();
        purchaseMixData: ChartData<number> = new ChartData<number>();
        purchaseMixDoughnutData: ChartData<string> = new ChartData<string>();
        
        cprpdChart: any = undefined;
        purchaseMixChart: any = undefined;

        keyMetrics: KeyMetrics = new KeyMetrics()

        reportRequest: AdviceReportRequest = new AdviceReportRequest();
        downloadAllReports: boolean = true

        // 
        // -- computed properties
        //

        get showCprpd(): boolean {
            return this.cprpdData.datasets.length > 0
        }

        get canViewAllAccounts(): boolean {
            return this.$store.state.canViewAllAccounts;
        }

        get showAvgOptions(): boolean {
            return (this.$store.state.includeInactive && this.customers.length > 1) ||
                   (!this.$store.state.includeInactive && this.customers.filter(c => c.isActive).length > 1);
        }

        get showChartOptions(): boolean {
            return this.canViewAllAccounts || this.showAvgOptions;
        }

        get includeGroupReport(): boolean {
            return this.reportRequest.groupAgreement == this.breakDownRequest.agreementNumber;
        }

        set includeGroupReport(value: boolean) {
            this.reportRequest.groupAgreement = value ? this.breakDownRequest.agreementNumber : 0;
        }

        get includeConsolidatedReport(): boolean {
            return this.reportRequest.consolidatedAgreement == this.breakDownRequest.agreementNumber;
        }

        set includeConsolidatedReport(value: boolean) {
            this.reportRequest.consolidatedAgreement = value ? this.breakDownRequest.agreementNumber : 0;
        }

        get reportIndeterminate(): boolean {
            return (this.reportRequest.dropAccounts.length > 0 || this.includeGroupReport || this.includeConsolidatedReport) &&
                   !(this.reportRequest.dropAccounts.length == this.customers.length && (!this.canViewAllAccounts || this.includeGroupReport || this.includeConsolidatedReport));
        }

        get adviceReportsTitle(): string {
            return `Download Advice Reports - ${Utils.monthYearText(this.reportRequest.date)}`
        }

        //
        // -- watchers
        //

        @Watch("$store.state.layoutInitDone", { immediate: true, deep: true })
        private async onLayoutInit(initComplete: boolean) {
            if (!initComplete) return;

            this.initDone = false;

            await this.getCustomers();

            this.cprpdRequest.agreementNumber = this.$store.state.agreementNumber;
            this.breakDownRequest.agreementNumber = this.$store.state.agreementNumber;
            this.breakDownRequest.dropAccountNumber = this.$store.state.dropAccount ?? 0;
            this.cprpdRequest.showAverage = this.showAvgOptions;
            this.cprpdRequest.showTarget = this.canViewAllAccounts;
            this.cprpdRequest.cprpdTarget = this.$store.state.cprpdTarget;
            
            this.initDone = true;
        }

        @Watch("cprpdRequest.showAverage")
        private async onShowAverageChanged() {
            await this.loadCPRPD();
        }

        @Watch("cprpdRequest.showTarget")
        private async onShowTargetChanged() {
            await this.loadCPRPD();
        }

        @Watch("cprpdRequest.cprpdTarget")
        private async onTargetChanged() {
            if (this.canViewAllAccounts) {
                await this.updateTarget();
                await this.loadCPRPD();
            }
        }

        @Watch("downloadAllReports")
        private onDownloadAllReportsChanged(value: boolean) {
            if (value) {
                this.reportRequest.groupAgreement = this.canViewAllAccounts ? this.breakDownRequest.agreementNumber : 0;
                this.reportRequest.consolidatedAgreement = this.canViewAllAccounts ? this.breakDownRequest.agreementNumber : 0;
                this.reportRequest.dropAccounts.push(...this.customers.map(c => c.dropAccount));
            }
            else {
                this.includeGroupReport = false;
                this.includeConsolidatedReport = false;
                this.reportRequest.dropAccounts = [];
            }
        }

        // 
        // -- methods
        //

        private getCustomers = async () => {      
            this.customers.length = 0;
            this.hiddenDropAccounts.length = 0;
            const agreementNumber = this.$store.state.agreementNumber;      
            const serverData = await ApiClient.get(`api/agreement/customers?agreementNumber=${agreementNumber}`);
            this.customers.push(...serverData.map((c: any) => new UserAgreementCustomer(c)));
        }

        private get availableCustomers(): Array<UserAgreementCustomer> {
            return this.customers.filter(c => c.isActive || this.$store.state.includeInactive);
        }

        private onCprpdDatasetClicked(event: ChartEvent, legendItem: LegendItem, legend: any) {
            const index = legendItem.datasetIndex!;
            const dataset = this.cprpdData.datasets[index];
            const dropAccount = dataset.id;
            this.onDatasetClicked(dropAccount);
        }

        private onDatasetClicked(dropAccount: string) {
            if (this.hiddenDropAccounts.length === 0) {
                this.cprpdData.datasets
                    .filter(d => d.id !== dropAccount && +d.id > 0)
                    .forEach(d => {
                        this.hiddenDropAccounts.push(d.id);
                    });
            }
            else if (this.hiddenDropAccounts.indexOf(dropAccount) > -1) {
                this.hiddenDropAccounts.splice(this.hiddenDropAccounts.indexOf(dropAccount), 1);
            }
            else {
                this.hiddenDropAccounts.push(dropAccount)

                if (this.cprpdRequest.showAverage && this.cprpdRequest.showTarget && this.hiddenDropAccounts.length === this.cprpdData.datasets.length - 2) {
                    this.hiddenDropAccounts = [];
                }
                else if ((this.cprpdRequest.showAverage || this.cprpdRequest.showTarget) && this.hiddenDropAccounts.length === this.cprpdData.datasets.length - 1) {
                    this.hiddenDropAccounts = [];
                }
                else if (!this.cprpdRequest.showAverage && !this.cprpdRequest.showTarget && this.hiddenDropAccounts.length === this.cprpdData.datasets.length) {
                    this.hiddenDropAccounts = [];
                }
            }

            this.updateVisibleDatasets();

            if (+dropAccount > 0 || this.hiddenDropAccounts.length == 0)
            this.loadPurchaseMix();
        }

        private updateVisibleDatasets() {
            this.cprpdChart?.data.datasets.forEach((_dataset: any, index: any) => {
                const datasetID = this.cprpdData.datasets[index].id;
                const hidden = this.hiddenDropAccounts.indexOf(datasetID) > -1;
                const meta = (this.cprpdChart as Chart).getDatasetMeta(index);
                meta.hidden = hidden;
            });
            this.cprpdChart?.update();
        }

        // cost per resident per day

        async loadCPRPD():Promise<void> {
            if (!this.initDone) return;
            if (!this.cprpdRequest.startDate || !this.cprpdRequest.endDate) return;

            const response = await ApiClient.post("api/agreement/costPerResidentPerDay", this.cprpdRequest);
            this.cprpdData.update(response);
            this.updateCPRPD();
            this.updateVisibleDatasets();
        }

        updateCPRPD():void {
            // create chart
            this.drawCPRPD();

            // double check chart has been created
            if (this.cprpdChart === undefined) {
                return;
            }

            // update datasets
            this.cprpdChart.data = this.cprpdData;
            this.cprpdChart.update();
        }

        drawCPRPD():void {
            // get canvas dom element 
            const canvas = (this.$refs.cprpdChart as HTMLCanvasElement);
            if (canvas === undefined) return;

            // get context from canvas
            const ctx = canvas.getContext("2d");
            if (ctx === undefined || ctx === null) return;

            // clear chart if already exists
            if (this.cprpdChart !== undefined) {
                this.cprpdChart.destroy();
            }
            
            // create chart
            this.cprpdChart = new Chart(ctx, {
                type: "line",
                data: {
                    datasets: []
                },
                options: {
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            suggestedMin: 2,
                            suggestedMax: 6,
                            ticks: {
                                callback: (value) => {
                                    return Utils.toMoney(+value);
                                }
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: this.$store.state.canChangeCustomer,
                            position: "right",
                            onClick: this.onCprpdDatasetClicked,
                            labels: {
                                filter: (item: LegendItem) => {
                                    return item.text !== "Average" && item.text !== "Target"
                                }
                            }
                        },
                        tooltip: {
                            callbacks: {
                                label: (toolTipItem: any): string => {
                                    if (toolTipItem.dataset.residents.length > 0) {
                                        const residents = toolTipItem.dataset.residents[toolTipItem.dataIndex];
                                        return`${toolTipItem.dataset.label}: ${Utils.toMoney(toolTipItem.raw)} (${residents} Residents)`;
                                    }
                                    else {
                                        return`${toolTipItem.dataset.label}: ${Utils.toMoney(toolTipItem.raw)}`;
                                    }                                    
                                }
                            }
                        }
                    }
                }
            });
        }

        private async updateTarget() {
            if (!this.initDone) return;

            const response: { message: string } = await ApiClient.get(`api/agreement/saveTarget?agreementNumber=${this.cprpdRequest.agreementNumber}&target=${this.cprpdRequest.cprpdTarget > 0 ? this.cprpdRequest.cprpdTarget : 0}`)
            if (response.message == "ok"){
                await store.dispatch("setCprpdTarget", this.cprpdRequest.cprpdTarget);
            }
        }

        // purchase mix

        async loadPurchaseMix():Promise<void> {
            if (!this.initDone) return;
            if (!this.breakDownRequest.startDate || !this.breakDownRequest.endDate) return;

            if (!Utils.isEmptyId(this.$store.state.dropAccount) || this.hiddenDropAccounts.filter(d => +d > 0).length + 1 === this.availableCustomers.length)
                await this.loadPurchaseMixDoughnut();
            else 
                await this.loadPurchaseMixBar();

            await this.loadKeyMetrics();

            this.updateVisibleDatasets();
        }

        async loadPurchaseMixBar() {
            if (!this.initDone) return;

            const dropAccounts = this.hiddenDropAccounts.length > 0
                ? this.availableCustomers.filter(customer => this.hiddenDropAccounts.indexOf(customer.dropAccount.toString()) === -1).map(customer => customer.dropAccount)
                : [];

            this.breakDownRequest.dropAccountNumbers = dropAccounts;

            const response = await ApiClient.post("api/agreement/purchaseMix", this.breakDownRequest);
            this.purchaseMixData.update(response);
            this.updatePurchaseMixBar();
        }

        private drawPurchaseMixBar() {
            // get canvas dom element 
            const canvas = (this.$refs.purchaseMixChart as HTMLCanvasElement);
            if (canvas === undefined) return;

            // get context from canvas
            const ctx = canvas.getContext("2d");
            if (ctx === undefined || ctx == null) return;

            // clear chart if already exists
            if (this.purchaseMixChart !== undefined) {
                this.purchaseMixChart.destroy();
            }

            // create chart
            this.purchaseMixChart = new Chart(ctx, {
                type: "bar",
                data: {
                    datasets: []
                },
                options: {
                    maintainAspectRatio: false,
                    scales: {
                        x: { stacked: true },
                        y: {
                            stacked: true,
                            min: 0,
                            max: 100,
                            ticks: {
                                callback: (value) => {
                                    return value + "%";
                                }
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            display: this.$store.state.canChangeCustomer,
                            position: "right"
                        },
                        tooltip: {
                            callbacks: {
                                label: (toolTipItem: any): string => {
                                    return`${toolTipItem.dataset.label}: ${toolTipItem.raw}%`;
                                }
                            }
                        }
                    },
                    onClick: this.purchaseMixClicked
                }
            });
        }

        purchaseMixClicked (_: ChartEvent, elements: ActiveElement[], chart: Chart): void {
            if (this.breakDownRequest.selectedFilter != ChartFilterOptions.SpecificDates) return;
            if (!elements.length) return;

            const element = elements[0]

            const dataSet = chart.data.datasets[element.datasetIndex] as any;
            const dropAccount = this.purchaseMixData.labelValues[element.index];
            const categoryID = +dataSet.id?.split("-")[0];
            const supplierID = +dataSet.id?.split("-")[1];

            if (!Utils.isEmptyId(supplierID)) {
                toastr.warning("We're unable to show purchases for third party suppliers", "Sorry!");
                return;
            }

            const dialogue = this.$refs.purchaseLinesDialogue as PurchasesDialogue;
            dialogue.viewPurchaseLines({
                agreementNumber: this.breakDownRequest.agreementNumber,
                dropAccountNumber: dropAccount,
                date: this.breakDownRequest.endDate,
                categoryID: categoryID,
                includeInactive: this.breakDownRequest.includeInactive,
                itemsPerPage: 20,
                pageNumber: 0
            })
        }

        private updatePurchaseMixBar() {
            // create chart
            this.drawPurchaseMixBar();

            // double check chart has been created
            if (this.purchaseMixChart === undefined) {
                return;
            }

            // update datasets
            this.purchaseMixChart.data = this.purchaseMixData;
            this.purchaseMixChart.update();
        }

        // purchase mix doughnut 

        private async loadPurchaseMixDoughnut() {
            if (!this.initDone) return;

            const dropAccount = !Utils.isEmptyId(this.$store.state.dropAccount) 
                ? this.$store.state.dropAccount
                : this.availableCustomers.filter(customer => this.hiddenDropAccounts.indexOf(customer.dropAccount.toString()) === -1)[0].dropAccount;

            this.breakDownRequest.dropAccountNumber = +dropAccount;

            const response = await ApiClient.post("api/agreement/purchaseMixDoughnut", this.breakDownRequest);
            this.purchaseMixDoughnutData.update(response);
            this.updatePurchaseMixDoughnut();
        }

        private drawPurchaseMixDoughnut() {
            // get canvas dom element 
            const canvas = (this.$refs.purchaseMixChart as HTMLCanvasElement);
            if (canvas === undefined) return;

            // get context from canvas
            const ctx = canvas.getContext("2d");
            if (ctx === undefined) return;

            // clear chart if already exists
            if (this.purchaseMixChart !== undefined) {
                this.purchaseMixChart.destroy();
            }

            // create chart
            this.purchaseMixChart = new Chart(ctx!, {
                type: "doughnut",
                data: {
                    datasets: []
                },
                options: {
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: "right"
                        },
                        tooltip: {
                            callbacks: {
                                label: (toolTipItem: any): string => {
                                    return`${toolTipItem.label}: ${toolTipItem.raw}%`;
                                }
                            }
                        }
                    },
                    onClick: this.purchaseMixDoughnutClicked
                }
            });            
        }

        purchaseMixDoughnutClicked (_: ChartEvent, elements: ActiveElement[], chart: Chart): void {
            if (this.breakDownRequest.selectedFilter != ChartFilterOptions.SpecificDates) return;
            if (!elements.length) return;

            const element = elements[0]

            const dataSet = chart.data.datasets[element.datasetIndex] as any;
            const dropAccount = +dataSet.id;
            const key: string = this.purchaseMixDoughnutData.labelValues[element.index];
            const categoryID = +key.split("-")[0];
            const supplierID = +key.split("-")[1];

            if (!Utils.isEmptyId(supplierID)) {
                toastr.warning("We're unable to show purchases for third party suppliers", "Sorry!");
                return;
            }

            const dialogue = this.$refs.purchaseLinesDialogue as PurchasesDialogue;
            dialogue.viewPurchaseLines({
                agreementNumber: this.breakDownRequest.agreementNumber,
                dropAccountNumber: dropAccount,
                date: this.breakDownRequest.endDate,
                categoryID: categoryID,
                includeInactive: this.breakDownRequest.includeInactive,
                itemsPerPage: 20,
                pageNumber: 0
            })
        }

        private updatePurchaseMixDoughnut() {
            // create chart
            this.drawPurchaseMixDoughnut();

            // double check chart has been created
            if (this.purchaseMixChart === undefined) {
                return;
            }

            // update datasets
            this.purchaseMixChart.data = this.purchaseMixDoughnutData;
            this.purchaseMixChart.update();
        }

        // key metrics 

        async loadKeyMetrics() {
            if (!this.initDone) return;
            if (!this.breakDownRequest.startDate || !this.breakDownRequest.endDate) return;

            const dropAccounts = this.hiddenDropAccounts.length > 0
                ? this.availableCustomers.filter(customer => this.hiddenDropAccounts.indexOf(customer.dropAccount.toString()) === -1).map(customer => customer.dropAccount)
                : [];

            this.breakDownRequest.dropAccountNumbers = dropAccounts;

            const repsonse: IKeyMetrics = await ApiClient.post("api/agreement/keyMetrics", this.breakDownRequest);
            this.keyMetrics.update(repsonse);
        }

        // advice reports

        showAdviceReportModal() {
            this.reportRequest.date = this.breakDownRequest.endDate;
            this.reportRequest.groupAgreement = this.canViewAllAccounts ? this.breakDownRequest.agreementNumber : 0;
            this.reportRequest.consolidatedAgreement = this.canViewAllAccounts ? this.breakDownRequest.agreementNumber : 0;
            this.reportRequest.dropAccounts.length = 0;
            this.reportRequest.dropAccounts.push(...this.customers.map(c => c.dropAccount));

            if (this.customers.length == 1) {
                this.downloadReports();
            }
            else {
                this.$bvModal.show("adviceReportModal");
            }
        }

        hideReportModal() {
            this.$bvModal.hide("adviceReportModal");
        }

        async downloadReports(event?: Event) {
            if (this.reportRequest.groupAgreement == 0 && this.reportRequest.consolidatedAgreement == 0 && this.reportRequest.dropAccounts.length == 0) {
                toastr.warning("Please select one or more reports");
                return;
            }

            const response = await ApiClient.post("api/agreement/adviceReports", this.reportRequest, event);
            Utils.downloadBlob(document, response, "Advice Reports.zip");
            this.$bvModal.hide("adviceReportModal");
        }
        
    }

